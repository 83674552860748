import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';

import { environment } from '../environments/environment';
import * as fromGenerated from './_generated';
import { provideTranslation } from './app.i18n';
import { appRoutes } from './app.routes';

import * as fromAuth from './auth';
import * as fromChats from './chats';

export const appConfig: ApplicationConfig = {
  providers: [
    provideEffects([fromAuth.AuthEffects, fromChats.ChatsEffects]),
    provideStore(),
    provideState(fromAuth.authFeature),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([fromAuth.authInterceptor])),
    importProvidersFrom([TranslateModule.forRoot(provideTranslation())]),
    importProvidersFrom([BrowserAnimationsModule]),
    importProvidersFrom([MarkdownModule.forRoot()]),
    importProvidersFrom([
      fromGenerated.ApiModule.forRoot(
        () =>
          new fromGenerated.Configuration({
            basePath: environment.apiBaseUrl,
            withCredentials: true,
          })
      ),
    ]),
    fromChats.ChatsSocket,
  ],
};
