import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as fromAuth from './auth';
import { PlaygroundComponent } from './playground.component';

@Component({
  standalone: true,
  imports: [PlaygroundComponent, RouterModule],
  selector: 'squadbox-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [``],
})
export class AppComponent implements OnInit {
  private readonly translateService = inject(TranslateService);
  private readonly authStore = inject<Store<fromAuth.AuthState>>(Store);

  constructor() {
    this.translateService.addLangs(['en']);
    this.translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.authStore.dispatch(fromAuth.AuthActions.loadUser());
  }
}
