import { Route } from '@angular/router';

import { provideState } from '@ngrx/store';
import * as fromAuth from './auth';
import * as fromChats from './chats';
import * as fromLayout from './layout';
import { NotFoundComponent } from './not-found.component';

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadComponent: () =>
      import('./auth/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'playground',
    loadComponent: () =>
      import('./playground.component').then((m) => m.PlaygroundComponent),
  },
  {
    path: '',
    canActivate: [fromAuth.authGuard],
    canActivateChild: [fromAuth.authGuard],
    providers: [provideState(fromLayout.layoutFeature)],
    loadComponent: () =>
      import('./layout/layout.component').then((m) => m.LayoutComponent),
    children: [
      {
        path: '',
        redirectTo: 'chats',
        pathMatch: 'full',
      },
      {
        path: 'chats',
        providers: [provideState(fromChats.chatsFeature)],
        loadComponent: () =>
          import('./chats/chats.component').then((m) => m.ChatsComponent),
      },
    ],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', component: NotFoundComponent },
];
